import { useMemo, useState } from 'react'
import { formatterPrice } from 'src/utils/formatterPrice'
import type { ProductItem } from 'src/components/product-page/context/types/product.type'

import { TableInstallment } from './TableInstallment'

type Props = {
  currentSku: ProductItem
  variant?: 'card-kit' | 'pdp'
}

export function Installment({ currentSku, variant = 'pdp' }: Props) {
  const [tableOpen, setTableOpen] = useState(false)

  const currentSeller =
    currentSku?.sellers.find((seller) => seller.sellerDefault) ??
    currentSku.sellers[0]

  const installmentFindBest = useMemo(() => {
    const paymentOptions =
      currentSeller.commertialOffer?.PaymentOptions?.installmentOptions.sort(
        (a, b) => b.installments?.length - a.installments?.length
      )[0]

    const paymentOptionsWithoutDecathlon =
      currentSeller.commertialOffer?.PaymentOptions?.installmentOptions
        .filter(
          (installment) =>
            installment.paymentSystem !== '501' &&
            installment.paymentSystem !== '45'
        )
        ?.sort((a, b) => b.installments?.length - a.installments?.length)[0]

    return {
      paymentOptionsWithoutDecathlon,
      paymentOptions,
    }
  }, [currentSeller])

  const installmentLastCount =
    installmentFindBest.paymentOptions?.installments?.length ?? 0

  const sellerInstallmentValue = {
    count:
      installmentFindBest.paymentOptions?.installments[installmentLastCount - 1]
        .count ?? 0,
    value:
      installmentFindBest.paymentOptions?.installments[installmentLastCount - 1]
        .value ?? 0,
  }

  if (sellerInstallmentValue.count <= 1) {
    return null
  }

  return (
    <div className="relative mt-1 w-fit">
      <p
        className={`${
          variant === 'pdp' ? 'text-sm' : 'text-xs'
        } text-darkGray font-roboto w-fit"`}
      >
        ou{' '}
        <button
          className="underline"
          data-value={sellerInstallmentValue.value / 100}
          onClick={() => setTableOpen((current) => !current)}
        >
          {sellerInstallmentValue.count > 10
            ? 10
            : sellerInstallmentValue.count}
          {''}x de{' '}
          {formatterPrice(
            sellerInstallmentValue.count > 10
              ? currentSeller.commertialOffer.Price / 10
              : sellerInstallmentValue.value / 100
          )}
        </button>
      </p>
      {tableOpen && <TableInstallment installments={installmentFindBest} />}
    </div>
  )
}
