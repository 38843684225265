import axios from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { ProductItem } from 'src/components/product-page/context/types/product.type'
import { SELLER_DECATHLON } from 'src/constants'

type CashbackItems = {
  itemId: string
  sellers: Array<{
    sellerId: string
    sellerName: string
    commertialOffer: {
      cashbackPrice: number
      cashbackPercentual: number
      cashbackPriceFormatted: string
    }
  }>
}

type Props = {
  currentSku: ProductItem
  productId: string
}

export function ProductSeller({ currentSku, productId }: Props) {
  const [cashbackItems, setCashbackItems] = useState<CashbackItems[]>([])

  const currentSeller = currentSku?.sellers.find(
    (seller) => seller.sellerDefault
  )

  const getCashbackData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://decathlonstore.myvtex.com/app/pub/cashback/productId/${productId}`
      )

      setCashbackItems(data.items)
    } catch {
      console.error('error on getAutocompleteData')
    }
  }, [productId])

  useEffect(() => {
    getCashbackData()
  }, [getCashbackData])

  const cashback = useMemo(() => {
    try {
      const currentSkuCashback = cashbackItems?.find(
        (item) => item.itemId === currentSku.itemId
      )

      const cashbackPercentual = currentSkuCashback?.sellers.find(
        (itemSeller) => itemSeller.sellerId === SELLER_DECATHLON
      )?.commertialOffer?.cashbackPercentual

      return cashbackPercentual
    } catch {
      console.error('error on getAutocompleteData')

      return null
    }
  }, [cashbackItems, currentSku.itemId])

  return (
    <div className="flex flex-col gap-y-2 p:gap-y-0 p:flex-row p:items-center p:justify-between mt-2">
      <p className="text-sm text-deepGray font-roboto flex items-center gap-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.478 1.88402L8 0.666687L2.522 1.88402C2.21733 1.95202 2 2.22202 2 2.53469V9.19335C2 10.53 2.668 11.7787 3.78133 12.5207L8 15.3334L12.2187 12.5207C13.3313 11.7794 14 10.53 14 9.19269V2.53469C14 2.22202 13.7827 1.95202 13.478 1.88402ZM7.668 8.78069L10.968 5.48135L11.9113 6.42402L7.66867 10.6667L4.84 7.83802L5.78267 6.89535L7.668 8.78069Z"
            fill="#02986E"
          />
        </svg>
        Vendido e entregue por{' '}
        <strong>
          {currentSeller?.sellerName.replace('Iguasport LTDA', 'Decathlon')}
        </strong>
      </p>

      {typeof cashback === 'number' &&
        cashback > 0 &&
        currentSeller?.sellerId === SELLER_DECATHLON && (
          <p className="bg-[#E6F9F3] text-[#017253] px-2 rounded-md w-fit">
            {cashback}% Cashback
          </p>
        )}
    </div>
  )
}
