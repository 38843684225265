import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
import { useLinxNotFoundView } from 'src/sdk/linx/hooks/useLinxNotFoundView'
import NotFound from 'src/components/NotFound/NotFound'

function Page() {
  const { sendNotFoundViewEvent } = useLinxNotFoundView()

  useEffect(() => {
    sendNotFoundViewEvent()
  }, [sendNotFoundViewEvent])

  return (
    <>
      <GatsbySeo />
      <NotFound />
    </>
  )
}

export default Page
